<template>
  <div>
    <div class="header">
      <div class="logo">
        <img src="../assets/image/logo.png" alt="" />
      </div>
    </div>
    <div class="index-bg">
      <div class="main-logo">
        <img src="../assets/image/途啦@2x.png" alt="" />
      </div>
      <div class="main-title">专注为客户提供高性价比，更好体验的服务</div>
      <div class="index-button">
        <div class="button" @click="windowOpen('https://hy.tulaway.cn')">
          <i class="icon icon-1"></i>
          <span>途啦网络货运平台</span>
        </div>
        <div class="button" @click="windowOpen('http://jc.tulaway.cn')">
          <i class="icon icon-2"></i>
          <span>途啦建材商城</span>
        </div>
      </div>
    </div>
    <footerComponents ref="footer"></footerComponents>
  </div>
</template>

<script>
import footerComponents from "@/components/footer"
export default {
  data() {
    return {};
  },
  components: { footerComponents },
  methods: {
    windowOpen(url) {
      window.open(url);
    }
  }
};
</script>

<style scoped lang="scss">
.index-bg {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: url(../assets/image/index-bg.jpg) repeat;
  background-size: 100% auto;
  background-position: center;
  .main-logo {
    position: absolute;
    top: 30%;
    left: 50%;
    margin-left: -90px;
    img {
      width: 100%;
    }
  }
  .main-title {
    position: absolute;
    top: 42%;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 2px;
  }
  .index-button {
    position: absolute;
    top: 52%;
    width: 100%;
    text-align: center;
    .button {
      display: inline-block;
      color: #ffffff;
      border-radius: 40px;
      text-align: center;
      height: 45px;
      width: 230px;
      line-height: 44px;
      background-color: #2f77db;
      margin: 0 100px;
      letter-spacing: 1px;
      cursor: pointer;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
    }
    .button:hover {
      background-color: #77acf5;
    }
  }
}
.header {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 9999;
  padding: 20px 0;
  .logo {
    width: 1200px;
    margin: auto;
    img {
      width: 80px;
    }
  }
}
</style>