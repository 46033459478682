<template>
  <div class="footer">
    <div class="main">
      <div class="tl" @click="routerJump('intro')">途啦</div>
      <div class="lxwm" @click="routerJump('contact')">联系我们</div>
      <div>经营许可证编号：闽B2-20210334</div>
      <div>网站备案：闽ICP备2021001508号-1</div>
      <div class="code">
        <img src="../assets/image/code.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    routerJump(name) {
      this.$router.push({
        name: name
      })
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  background-color: #f7f7f7;
  position: fixed;
  bottom: 0px;
  padding: 14px 0;
  .main {
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    color: #333333;
    div {
      display: inline-block;
      font-size: 14px;
      height: 20px;
      margin-top: 20px;
    }
    .tl {
      padding-left: 28px;
      background: url(../assets/image/公司@2x.png) no-repeat;
      cursor: pointer;
    }
    .lxwm {
      padding-left: 28px;
      background: url(../assets/image/联系@2x.png) no-repeat;
      cursor: pointer;
    }
    .code {
      width: 64px;
      height: 64px;
      margin: 0;
      img {
        width: 100%;
      }
    }
  }
}
</style>